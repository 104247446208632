import isoFetch from 'isomorphic-fetch';
import { submitRequest } from 'lib/service';

export const fetchInstagramData = async (instagramUrl, adminUrl) => {
  //const url = `${adminUrl}/embed_instagram?url=${instagramUrl}`;
  //const instagramResponse = await submitRequest(url, {}, 'GET');
  const instagramResponse = {
    feeds: [
      {
        image:
          'https://scontent-lcy1-2.cdninstagram.com/v/t51.2885-15/269954401_670535390794826_7277705837711605880_n.jpg?_nc_cat=1\u0026ccb=1-5\u0026_nc_sid=8ae9d6\u0026_nc_ohc=QdELISbO_GYAX8CgCt7\u0026_nc_ht=scontent-lcy1-2.cdninstagram.com\u0026edm=ANo9K5cEAAAA\u0026oh=00_AT-\u0026oe=61CBE32E',
        url: 'https://www.instagram.com/p/Cmnjh4IpU7N/?img_index=1',
        caption: 'created by API',
        creation_date: '2024-07-04 08:13:42',
      },
    ],
    url: 'https://www.instagram.com/xataka',
  };
  return instagramResponse;
};

export const fetchInfogramData = (infogramId) => {
  return isoFetch(
    'https://infogram.com/oembed?url=https://infogram.com/' +
      encodeURIComponent(infogramId)
  ).then((res) => res.json());
};

export const searchEcommerceProduct = async (
  adminUrl,
  store,
  criteria,
  searchValue
) => {
  store = store.toLowerCase();
  const url = `${adminUrl}/${store}/products?criteria=${criteria}&searchValue=${searchValue}`;
  const products = await submitRequest(url, {}, 'GET');
  return products;
};

export const fetchFlipboardData = (adminUrl, site) => {
  const url = `${adminUrl}/api/${site}/flipboard`;

  return isoFetch(url, { credentials: 'include' }).then((res) => res.json());
};

export const fetchInstagramFeeds = (adminUrl, site) => {
  const url = `${adminUrl}/api/instagram/feeds/${site}`;

  return isoFetch(url, { credentials: 'include' }).then((res) => res.json());
};

export const searchEcommerceProducts = async (adminUrl, searchValue) => {
  searchValue = encodeURIComponent(searchValue);
  const url = `${adminUrl}/api/ecommerce_products?searchValue=${searchValue}`;
  const products = await submitRequest(url, {}, 'GET');
  return products;
};

export const searchTradeDoubler = async (adminUrl, ean) => {
  ean = encodeURIComponent(ean);
  const url = `${adminUrl}/api/ecommerce_products/tradedoubler?ean=${ean}`;
  const products = await submitRequest(url, {}, 'GET');
  return products;
};
