// @flow
import * as React from 'react';
import { Link } from 'react-router';
import styles from './RouterLink.module.css';

type Props = {
  children: React.Node,
};

export const RouterLink = ({ children, ...props }: Props): React.Node => (
  <span className={styles.colorInherit}>
    <Link {...props}>{children}</Link>
  </span>
);
