// @flow
import React from 'react';
import {
  ToolTipText,
  Appbar,
  Grid,
  Tabs,
  Tab,
  H6,
  VisibilityIcon,
  SaveIcon,
  IconButton,
} from 'material-design';
import styles from './Header.module.css';
import { getSiteIcon } from 'lib/helpers';
import { POST_TYPE_LANDING } from 'lib/constants';
import { PREVIEW_PATH } from 'constants/index';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';

type CustomHeaderProps = {
  activeTab?: string,
  showTabs?: boolean,
  siteName: string,
  siteUrl: string,
  adminUrl: string,
  tabs?: { value: string, title: string }[],
  postId: string,
  postName: string,
  postType: string,
  saveData: Function,
};

const getTitle = (postType = '', postId) => {
  let title = POST_TYPE_LANDING === postType ? 'Landing' : 'Post';

  if (postId) {
    title += ` #${postId}`;
  }

  return title;
};

const Header = ({
  postId,
  postName,
  postType,
  activeTab,
  showTabs = true,
  siteName,
  siteUrl,
  adminUrl,
  tabs = [
    { value: 'escribir', title: 'CONTENIDO' },
    { value: 'backup', title: 'HERRAMIENTAS' },
    { value: 'publicar', title: 'PUBLICACIÓN' },
  ],
  saveData,
}: CustomHeaderProps) => {
  let navigate = useNavigate();
  const title = getTitle(postType, postId);

  const handleTabSwitchChange = (event, route: string) => {
    navigate(`/${route}/${postName}`);
  };

  const previewUrl = siteUrl + PREVIEW_PATH + postName;

  return (
    <Appbar>
      <Grid
        container
        gutter="custom"
        spacing={0}
        alignItems={{ xs: 'flex-start', sm: 'flex-end' }}
        justifyContent="space-between"
      >
        <Grid item xs={6} md={3} sx={{ order: { xs: 1, md: 1 } }}>
          <div className={styles.title}>
            {getSiteIcon(siteName, adminUrl)}
            <H6 color="inherit">{title}</H6>
          </div>
        </Grid>

        {showTabs && (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', sm: 'center' },
              order: { xs: 3, md: 2 },
            }}
          >
            <div className={styles.overflowTabs}>
              <Tabs value={activeTab} onChange={handleTabSwitchChange}>
                {tabs.map((tab) => (
                  <Tab key={tab.value} value={tab.value} title={tab.title} />
                ))}
              </Tabs>
            </div>
          </Grid>
        )}

        <Grid item xs={6} md={3} sx={{ order: { xs: 2, md: 3 } }}>
          <div className={styles.iconWrapper}>
            <ToolTipText title="Guardar">
              <span>
                <IconButton
                  background="transparent"
                  size="large"
                  onClick={saveData}
                >
                  <SaveIcon color="inherit" fontSize="medium" />
                </IconButton>
              </span>
            </ToolTipText>

            <ToolTipText title="Vista Preview">
              <span>
                <IconButton
                  background="transparent"
                  size="large"
                  onClick={() => window.open(previewUrl)}
                >
                  <VisibilityIcon color="inherit" fontSize="medium" />
                </IconButton>
              </span>
            </ToolTipText>
          </div>
        </Grid>
      </Grid>
    </Appbar>
  );
};

const mapStateToProps = (state) => {
  return {
    postType: state.post.postType,
    postId: state.post.postId,
  };
};

export default connect(mapStateToProps, {})(Header);
